const DEPOSIT_CAL_METHOD = {
  PER_MONTH: 'month',
  CUMULATIVE: 'cumulative',
};

export const LEVEL = {
  1: {
    NAME: 'silver',
    STATUS: 1,
    DISABLED: false,
    CRITERIA: DEPOSIT_CAL_METHOD.CUMULATIVE,
    IMG: '/assets/images/user/600x200-01-silver.png',
    LOST_PERCENT: 5,
    COLOR: '#42474A',
    DETAILS: {
      TEXT_1: 'ต้องมียอดฝากรวมทั้งหมด 1 บาท ขึ้นไป',
      TEXT_2: 'คืนยอดเสียที่ 5%',
      TEXT_3: 'ถอนได้สูงสุด 100,000 บาทต่อวัน',
      TEXT_4: 'ถอนได้ 5 ครั้งต่อวัน',
    },
  },
  2: {
    NAME: 'gold',
    STATUS: 2,
    DISABLED: false,
    CRITERIA: DEPOSIT_CAL_METHOD.CUMULATIVE,
    IMG: '/assets/images/user/600x200-02-gold.png',
    LOST_PERCENT: 5,
    NEXT_LEVEL_AMOUNT: 50000,
    COLOR: '#F9D706',
    DETAILS: {
      TEXT_1: 'ต้องมียอดฝากรวมทั้งหมด 50,000 บาท ขึ้นไป',
      TEXT_2: 'คืนยอดเสียที่ 5%',
      TEXT_3: 'ถอนได้สูงสุด 300,000 บาทต่อวัน',
      TEXT_4: 'ถอนได้ 5 ครั้งต่อวัน',
    },
  },
  3: {
    NAME: 'platinum',
    STATUS: 3,
    DISABLED: false,
    CRITERIA: DEPOSIT_CAL_METHOD.CUMULATIVE,
    IMG: '/assets/images/user/600x200-03-platinum.png',
    LOST_PERCENT: 7,
    NEXT_LEVEL_AMOUNT: 300000,
    COLOR: '#06587E',
    DETAILS: {
      TEXT_1: 'ต้องมียอดฝากรวมใน 1 เดือน 300,000 บาท ขึ้นไป',
      TEXT_2: 'คืนยอดเสียที่ 7%',
      TEXT_3: 'ถอนได้สูงสุด 500,000 บาทต่อวัน',
      TEXT_4: 'ถอนได้ 5 ครั้งต่อวัน',
    },
  },
  4: {
    NAME: 'diamond',
    STATUS: 4,
    DISABLED: false,
    CRITERIA: DEPOSIT_CAL_METHOD.PER_MONTH,
    IMG: '/assets/images/user/600x200-04-diamond.png',
    LOST_PERCENT: 10,
    NEXT_LEVEL_AMOUNT: 1000000,
    MAX_AMOUNT: 200000,
    COLOR: '#9A070B',
    DETAILS: {
      TEXT_1: 'ต้องมียอดฝากรวมใน 1 เดือน 1,000,000 บาท ขึ้นไป',
      TEXT_2: 'คืนยอดเสียที่ 10%',
      TEXT_3: 'ถอนได้สูงสุด 1,000,000 บาทต่อวัน',
      TEXT_4: 'ถอนได้ 5 ครั้งต่อวัน',
    },
  },
  5: {
    NAME: 'vip',
    STATUS: 5,
    DISABLED: true,
    CRITERIA: DEPOSIT_CAL_METHOD.PER_MONTH,
    IMG: '/assets/images/user/600x200-04-vip.png',
    LOST_PERCENT: 15,
    NEXT_LEVEL_AMOUNT: 5000000,
    MAX_AMOUNT: 200000,
    COLOR: '#000000',
    DETAILS: {
      TEXT_1: 'ต้องมียอดฝากต่อเดือน 5,000,000 บาท ขึ้นไป',
      TEXT_2: 'คืนยอดเสีย ที่ 15 %',
      TEXT_3: 'ถอนสูงสุด 100,000 บาท ต่อครั้ง',
      TEXT_4: 'ถอนได้ 5 ครั้งต่อวัน',
    },
  },
  6: {
    NAME: 'vip',
    STATUS: 6,
    DISABLED: true,
    CRITERIA: DEPOSIT_CAL_METHOD.PER_MONTH,
    IMG: '/assets/images/user/600x200-04-vip.png',
    NEXT_LEVEL_AMOUNT: 99999999,
  },
};
